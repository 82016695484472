import React from 'react'
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import { Flex, Box, Input } from '@chakra-ui/react';
import { MdDateRange } from "react-icons/md";
import "react-datepicker/dist/react-datepicker.css";
import { DateRangePicker } from 'react-date-range';

const SingleDatePicker = ({ date, setDate }) => {

    return (
        <Flex p='3px' bg='teal.400' fontSize='30px' w='160px' fontWeight='bold' alignItems='center' gap='5px' cursor='pointer' borderRadius='3px'>
            <MdDateRange />
            <SingleDatepicker
                name="date-input"
                icon={<MdDateRange />}
                date={date}
                onDateChange={setDate}
                cursor='pointer'
            />

        </Flex>

    )
}

export default SingleDatePicker