import { Box, Text, Flex, Spacer } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import SingleDatePicker from '../../componunts/datepicker/SingleDatePicker';
import ExportBtn from '../../componunts/ExportBtn/ExportBtn';
import { format } from 'date-fns';
import DataTable from 'react-data-table-component';
import Loader from '../../componunts/loading/Loader';
import { datewisereport } from '../../request';

const Attendence = () => {
    let [userData, setUserData] = useState([]);
    let [Loading, setLoading] = useState(false);
    let [singleDate, setSingleDate] = useState(new Date());

    const getdata_datewise = async () => {
        setLoading(true)
        try {
            let data = await datewisereport(format(singleDate, 'yyyy-MM-dd').toString());
            if (data.status === 200) {
                setUserData(data.data.response);
                setLoading(false)
            }
        } catch (err) {
            console.log({ Error: err.message })
            // Navigate('/signin')
        }
    };

    const columns = [
        {
            name: <Text as='b' fontSize='15px'>BDM Name</Text>,
            selector: row => <Text fontSize='15px'>{row.bdm_name}</Text>
            ,
            // sortable: true,
        },
        {
            name: <Text as='b' fontSize='15px'>First-Checkin Time</Text>,
            selector: row => <Text fontSize='15px' color={checkinTimeMarking(row.checkin_time, 10) ? 'red' : ''}>{row.checkin_time}</Text>,
            // sortable: true,
        },
        {
            name: <Text as='b' fontSize='15px'>Last-checkout Time</Text>,
            selector: row => <Text fontSize='15px'>{row.checkout_time}</Text>,
            // sortable: true,
        },
        {
            name: <Text as='b' fontSize='15px'>Total Working Hrs</Text>,
            //Two Chages For function getTimeDifference
            // selector: row => <Text fontSize='15px' color={WorkingHrsMarking(getTimeDifference(row.checkout_time, row.checkin_time))?'red':''} >{row.checkout_time !== '-' || row.checkin_time !== '-'  ?getTimeDifference(row.checkout_time, row.checkin_time) : '-'}</Text>,
            selector: row => <Text color={WorkingHrsMarking(row.totalWorkingHour_h) ? 'red' : ''} fontSize='15px'>{row.totalWorkingHour_h}</Text>,
            // sortable: true,
        }
    ];

    const checkinTimeMarking = (time, BM) => {
        time = time.split(':').map(Number);
        let flag = false;
        if (time[0] > BM || (time[0] === BM && (time[1] > 0 || time[2] > 0))) {
            flag = true;
        };
        return flag
    };

    const WorkingHrsMarking = (time) => {
        time = time.split(':').map(Number);
        let flag = false;
        if (time[0] < 6) {
            flag = true;
        };
        return flag
    };



    useEffect(() => {
        getdata_datewise()
    }, [singleDate]);


    return (
        <Box w='100%'>
            <Flex gap='10px' justifyContent='left' alignItems='center' mb='10px'>

                <SingleDatePicker date={singleDate} setDate={setSingleDate} />
                {/* <Box bg='teal.400' w='fit-content'>
            <Dropdown handelUser={FindUser} singleDate={singleDate} userID={userID} />
        </Box> */}
                <Spacer />
                <ExportBtn data={userData} filename={`${format(singleDate, 'yyyy-MM-dd').toString()}-BDM_report.csv`} />
            </Flex>

            {!Loading ?
                <DataTable
                    columns={columns}
                    data={userData}
                    striped='true'
                    pagination
                    paginationPerPage={15}
                    paginationRowsPerPageOptions={[10, 15, 20, 25]}
                /> :
                <Loader />
            }

        </Box>
    )
}

export default Attendence